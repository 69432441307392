<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas='breadcrumb_data' />
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('c_management.trading_failed_transactions') }}
              </h3>
            </div>
            <div class="card-toolbar">
              <datatable-dropdown/>
              <!--begin::Button-->
              <router-link
                to="/users-quicksalebuy"
                class="btn btn-secondary font-weight-bolder"
              >
                <i class="la la-chevron-left"></i>
                {{ $t("c_management.trading_subtitle") }}
              </router-link>
              <!--end::Button-->
            </div>
          </div>
          <div class='card-body'>
            <div v-if="isLoading">
              <table class="table table-striped table-bordered collapsed mt-20" style="width:100%" >
                <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{{ $t('commons.fullname') }}</th>
                  <th scope="col">{{ $t('commons.transaction_type') }}</th>
                  <th scope="col">{{ $t('commons.amount') }}</th>
                  <th scope="col">{{ $t('commons.price') }}</th>
                  <th scope="col">{{ $t('commons.cost') }}</th>
                  <th scope="col">{{ $t('commons.commission') }}</th>
                  <th scope="col">{{ $t('commons.creation_date') }}</th>
                  <th scope="col">{{ $t('commons.status') }}</th>
                  <th scope="col">{{ $t('c_management.result') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in 10" :key="'loading-' + index">
                  <th scope="row"><Skeleton height="38px" /></th>
                  <td><Skeleton height="38px" /></td>
                  <td><Skeleton height="38px" /></td>
                  <td><Skeleton height="38px" /></td>
                  <td><Skeleton height="38px" /></td>
                  <td><Skeleton height="38px" /></td>
                  <td><Skeleton height="38px" /></td>
                  <td><Skeleton height="38px" /></td>
                  <td><Skeleton height="38px" /></td>
                  <td><Skeleton height="38px" /></td>
                </tr>
                </tbody>
              </table>
            </div>
            <table class="table table-striped table-bordered collapsed" style="width:100%" ref="kt_datatable" v-else>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{{ $t('commons.fullname') }}</th>
                  <th scope="col">{{ $t('commons.transaction_type') }}</th>
                  <th scope="col">{{ $t('commons.amount') }}</th>
                  <th scope="col">{{ $t('commons.price') }}</th>
                  <th scope="col">{{ $t('commons.cost') }}</th>
                  <th scope="col">{{ $t('commons.commission') }}</th>
                  <th scope="col">{{ $t('commons.creation_date') }}</th>
                  <th scope="col">{{ $t('commons.status') }}</th>
                  <th scope="col">{{ $t('c_management.result') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in failed_trading_data" :key="'trading-' + index">
                  <th scope="row">{{ item.id }}</th>
                  <td>{{ item.user.name || '' }} {{ item.user.surname || '' }}</td>
                  <td>{{ item.type }}</td>
                  <td>{{ item.amount }}</td>
                  <td>{{ item.price }}</td>
                  <td>{{ item.total }}</td>
                  <td>{{ item.fee }}</td>
                  <td>{{ item.created_at }}</td>
                  <td v-html="item.status"></td>
                  <td>{{ item.state }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import {mapState} from 'vuex';
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import DatatableDropdown from '@/components/datatable/Dropdown.vue'
import $ from "jquery";
import { Skeleton } from 'vue-loading-skeleton';


export default {
  name: 'TradingFailedTransactions',
  data() {
    return { }
  },
  components: {
    Breadcrumb,
    Skeleton,
    DatatableDropdown,
  },
  computed: {
    ...mapState({
      isLoading: state => state.customerManagement.isLoading,
      failed_trading_data: state => state.customerManagement.failed_trading_data,
      permissions: (state) => state.customer.permissions,
      lang: (state) => state.global.lang
    }),
    breadcrumb_data() {
      return {
        title: this.$t('c_management.title'),
        subtitle: [
          { title: this.$t("c_management.trading_transactions"), route: '/users-quicksalebuy' },
          this.$t('c_management.trading_failed_transactions')
        ]
      }
    }
  },
  methods: {
    checkPermission(permission) {
      return this.permissions?.some(val => val.name == permission);
    },
  },
  created() {
    if(this.permissions?.length > 0 && !this.checkPermission('crm.users.quicksalebuy')) {
      this.$router.push('/no-permission')
    }
    const self = this;
    self.$store.dispatch('customerManagement/GET_FAILED_TRADING_DATA').then(function () {
      self.$nextTick(function (){
        var fileName = 'quicksale-failed-transactions';
        $(self.$refs['kt_datatable']).DataTable({
          responsive: true,
          order: [[ 0, 'desc' ]],
          pagingType: 'full_numbers',
          dom: 'Blfrtip',
          buttons: ['copy', 'csv', 'excel', 'pdf', 'print'].map(el => (
            {
              extend: el,
              filename: fileName
            }
          )),
          lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
          pageLength: 10,
          initComplete: function() {
            $('.dt-buttons').hide();
          },
          columnDefs: [
            { orderable: false, targets: -1 },
            { responsivePriority: 1, targets: 0 },
            { responsivePriority: 2, targets: -1 }
          ],
          language: {
              url: `/assets/languages/datatable/${this.lang}.json`
          }
        });
        $("#printExport").on("click", function(e) {
          e.preventDefault();
          $(".buttons-print").trigger("click");
        });
        $("#copyExport").on("click", function(e) {
          e.preventDefault();
          $(".buttons-copy").trigger("click");
        });
        $("#excelExport").on("click", function(e) {
          e.preventDefault();
          $(".buttons-excel").trigger("click");
        });
        $("#csvExport").on("click", function(e) {
          e.preventDefault();
          $(".buttons-csv").trigger("click");
        });
        $("#pdfExport").on("click", function(e) {
          e.preventDefault();
          $(".buttons-pdf").trigger("click");
        });

      })
    })
  },
}
</script>
<style lang="scss">
</style>
